<template>
  <v-container fluid>
    <v-row justify="space-around">
      <v-col cols="12" sm="10" xl="8">
        <v-card v-if="loaded && token_valid">
          <v-card-title>
            Réinitialiser votre mot de passe
          </v-card-title>
          <v-card-text>
            Entrez votre nouveau mot de passe
            <v-text-field label="Mot de passe" type="password" v-model="password"
                          :rules="[eightCharactersLongMin, mustHaveNumber]"></v-text-field>
            <v-text-field label="Confirmer le mot de passe" type="password" v-model="passwordConfirmation"
                          :rules="[samePassword]"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="changePassword" color="primary">
              Réinitialiser le mot de passe
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else-if="loaded && !token_valid">
          <v-card-title>
            Réinitialiser votre mot de passe
          </v-card-title>
          <v-card-text class="red--text">
            La demande de réinitialisation n'est pas valide.
          </v-card-text>
          <v-card-actions><v-btn color="primary" to="/">Retour à l'écran de connexion</v-btn></v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title>
            Réinitialiser votre mot de passe
          </v-card-title>
          <v-row justify="space-around">
            <v-col cols="2">
              <v-progress-circular></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "PasswordReset",
  async mounted() {
    this.token = this.$route.query.token;
    console.log(this.token);
    if(this.token) {
      let result = await this.$store.dispatch("passwordreset/validateToken", this.token);
      if(result === "success") {
        this.loaded = true;
        this.token_valid = true;
      } else {
        this.loaded = true;
        this.token_valid = false;
      }
    } else {
      this.loaded = true;
      this.token_valid = false;
    }
  },
  data() {
    return {
      loaded: false,
      token_valid: false,
      token: null,
      password: "",
      passwordConfirmation: "",
    }
  },
  methods: {
    eightCharactersLongMin(value) {
      return value.length > 7 || 'Le mot de passe doit faire au moins 8 caractères.';
    },
    mustHaveNumber(value) {
      return /\d/.test(value) || "Doit avoir un nombre";
    },
    samePassword() {
      return this.password === this.passwordConfirmation || "Les deux mots de passe doivent être identiques";
    },
    async changePassword() {
      let result = await this.$store.dispatch("passwordreset/confirmPasswordReset", {
        password: this.password,
        token: this.token,
      })
      if(result === "success") {
        this.$store.dispatch("messages/announceSuccess", "Mot de passe modifié.");
        this.$router.push("/");
      } else {
        this.$store.dispatch("alerts/announceError", "Une erreur est survenue. Le mot de passe choisi " +
            "n'est pas valide ou votre demande a peut être expiré.");
      }
    }
  }
}
</script>